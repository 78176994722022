export default {
	"defaultWidth": 1280,
	"breakpoints": {
		"sm": [
			{
				"type": "max-width",
				"value": "576"
			}
		],
		"md": [
			{
				"type": "max-width",
				"value": 768
			}
		],
		"lg": [
			{
				"type": "max-width",
				"value": 992
			}
		]
	},
	"color": {
		"dark": "#04080C",
		"darkL1": "#FFC93C",
		"darkL2": "#99154E",
		"grey": "#C4C4C4",
		"greyD1": "#637897",
		"greyD2": "#586D8E",
		"light": "#ffffff",
		"lightD1": "#FFDDCC",
		"lightD2": "#FFBBCC",
		"green": "#00875A",
		"primary": "#206CEC",
		"secondary": "#FFC400",
		"orange": "#FF7C22",
		"red": "#DE350B",
		"purple": "#FD6DF9",
		"indigo": "#9B6CFC"
	},
	"fontFamily": {
		"sans": "-apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
		"sansHeavy": "Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif",
		"sansHelvetica": "Frutiger, 'Frutiger Linotype', Univers, Calibri, 'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue', Helvetica, Arial, sans-serif",
		"sansVerdana": "Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', Verdana, 'Verdana Ref', sans-serif",
		"sansTrebuchet": "'Segoe UI', Candara, 'Bitstream Vera Sans', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'Verdana Ref', sans-serif",
		"mono": "Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace",
		"serifTimes": "Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif",
		"serifGeorgia": "Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida, 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', Georgia, serif",
		"serifGaramond": "'Palatino Linotype', Palatino, Palladio, 'URW Palladio L', 'Book Antiqua', Baskerville, 'Bookman Old Style', 'Bitstream Charter', 'Nimbus Roman No9 L', Garamond, 'Apple Garamond', 'ITC Garamond Narrow', 'New Century Schoolbook', 'Century Schoolbook', 'Century Schoolbook L', Georgia, serif",
		"googleFiraSans": "\"Fira Sans\", sans-serif",
		"googleDmSerifDisplay": "\"DM Serif Display\", serif",
		"googleMeowScript": "\"Meow Script\", cursive",
		"googleYesteryear": "\"Yesteryear\", cursive",
		"googleMonsieurLaDoulaise": "\"Monsieur La Doulaise\", cursive",
		"googleAbrilFatface": "\"Abril Fatface\", cursive",
		"googleChangaOne": "\"Changa One\", cursive",
		"googleBowlbyOneSc": "\"Bowlby One SC\", cursive",
		"googleGravitasOne": "\"Gravitas One\", cursive"
	},
	"font": {
		"headline1": "normal normal 500 70px/1.2 --fontFamily-serifGaramond",
		"headline2": "400 50px/1.2 --fontFamily-serifGaramond",
		"headline3": "400 40px/1.2 --fontFamily-googleFiraSans",
		"base": "normal 400 20px/1.5 --fontFamily-sansTrebuchet",
		"lead": "normal 400 20px/1.5 --fontFamily-googleFiraSans",
		"headline4": "normal 400 28px/1.3 --fontFamily-googleFiraSans"
	},
	"boxShadow": {
		"m": "0 4px 5px -1px rgba(0, 0, 0, 0.1)",
		"l": "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
		"xl": "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
		"xxl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)"
	},
	"background": {
		"primaryGradient": "linear-gradient(180deg, --color-primary, transparent) no-repeat 0 0",
		"secondaryGradient": "linear-gradient(180deg, --color-secondary, transparent) no-repeat 0 0"
	},
	"transform": {
		"rotate90": "rotate(90deg)",
		"rotate180": "rotate(180deg)",
		"flipX": "scaleX(-1)",
		"flipY": "scaleY(-1)"
	},
	"transition": {
		"opacityOut": "opacity --transitionDuration-normal --transitionTimingFunction-easeOut",
		"transformOut": "transform --transitionDuration-normal --transitionTimingFunction-easeOut",
		"transformInOut": "transform --transitionDuration-normal --transitionTimingFunction-easeInOut"
	},
	"transitionTimingFunction": {
		"easeIn": "cubic-bezier(0.4, 0, 1, 1)",
		"easeOut": "cubic-bezier(0, 0, 0.2, 1)",
		"easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
		"sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
	},
	"transitionDuration": {
		"fastest": "0.1s",
		"fast": "0.2s",
		"normal": "0.3s"
	},
	"filter": {
		"grayscale": "grayscale(100%)",
		"invert": "invert(100%)",
		"blur": "blur(10px)"
	},
	"animation": {},
	"keyframes": {
		"fadeIn": [
			{
				"key": "from",
				"props": [
					{
						"opacity": 0
					}
				]
			},
			{
				"key": "to",
				"props": [
					{
						"opacity": 1
					}
				]
			}
		],
		"fadeOut": [
			{
				"key": "from",
				"props": [
					{
						"opacity": 1
					}
				]
			},
			{
				"key": "to",
				"props": [
					{
						"opacity": 0
					}
				]
			}
		]
	},
	"components": {
		"section": {
			"maxWidth": {
				"default": "1280px"
			},
			"minWidth": {
				"default": "300px"
			},
			"width": {
				"default": "90%"
			}
		},
		"stack": {
			"gap": {
				"default": "32px",
				"small": "16px"
			}
		}
	},
	"fonts": {
		"Fira Sans": {
			"family": "Fira Sans",
			"type": "google-fonts",
			"meta": {
				"category": "sans-serif",
				"variants": [
					"100",
					"100italic",
					"200",
					"200italic",
					"300",
					"300italic",
					"regular",
					"italic",
					"500",
					"500italic",
					"600",
					"600italic",
					"700",
					"700italic",
					"800",
					"800italic",
					"900",
					"900italic"
				]
			}
		},
		"DM Serif Display": {
			"family": "DM Serif Display",
			"type": "google-fonts",
			"meta": {
				"category": "serif",
				"variants": [
					"regular",
					"italic"
				]
			}
		},
		"Meow Script": {
			"family": "Meow Script",
			"type": "google-fonts",
			"meta": {
				"category": "handwriting",
				"variants": [
					"regular"
				]
			}
		},
		"Yesteryear": {
			"family": "Yesteryear",
			"type": "google-fonts",
			"meta": {
				"category": "handwriting",
				"variants": [
					"regular"
				]
			}
		},
		"Monsieur La Doulaise": {
			"family": "Monsieur La Doulaise",
			"type": "google-fonts",
			"meta": {
				"category": "handwriting",
				"variants": [
					"regular"
				]
			}
		},
		"Abril Fatface": {
			"family": "Abril Fatface",
			"type": "google-fonts",
			"meta": {
				"category": "display",
				"variants": [
					"regular"
				]
			}
		},
		"Changa One": {
			"family": "Changa One",
			"type": "google-fonts",
			"meta": {
				"category": "display",
				"variants": [
					"regular",
					"italic"
				]
			}
		},
		"Bowlby One SC": {
			"family": "Bowlby One SC",
			"type": "google-fonts",
			"meta": {
				"category": "display",
				"variants": [
					"regular"
				]
			}
		},
		"Gravitas One": {
			"family": "Gravitas One",
			"type": "google-fonts",
			"meta": {
				"category": "display",
				"variants": [
					"regular"
				]
			}
		}
	},
	"version": 21
}